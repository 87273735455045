<!-- 成本分摊汇总 -->
<template>
  <div class="main-cnt statement-container store-container">
    <div class="flex-btw">
      <div class="flex flex-shrink">
        <el-select v-model="formData.s_id" placeholder="请选择园区" @change="onSwitchTabs">
          <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id"></el-option>
        </el-select>

        <el-date-picker v-model="formData.month" type="month" placeholder="请选择月份" class="s-m-l-r" :clearable="false"
          @change="onSwitchTabs" format="YYYY-MM" value-format="YYYY-MM">
        </el-date-picker>

        <el-select class="s-m-l-r" v-model="formData.stt_id" clearable collapse-tags placeholder="请选择卡类型"
          @change="onSwitchTabs">
          <el-option :label="item.stt_name" :value="item.stt_id" v-for="item in typeOptions" :key="item.stt_id">
          </el-option>
        </el-select>
      </div>

      <el-tooltip class="item" effect="dark" content="导出" placement="left">
        <span class="border-icon" @click="handleExport">
          <i class="iconfont icon-a-lujing207"></i></span>
      </el-tooltip>
    </div>

    <div class="content">
      <el-tabs v-model="activeName" @tab-click="onSwitchTabs">
        <!-- 成本分摊汇总 -->
        <el-tab-pane label="成本分摊汇总" name="1">
          <common-table ref="summaryTableRef" tableHeight="calc(100vh - 290px)" :ischeck="false" :ispaging="false"
            :apiName="AnalyseApi.getCost" :columns="summaryTableColumns" :extraParame="formData">
          </common-table>
        </el-tab-pane>
        <el-tab-pane label="成本分摊明细" name="2">
          <common-table ref="detailTableRef" tableHeight="calc(100vh - 350px)" :ischeck="false"
            :apiName="AnalyseApi.getCost" :columns="detailTableColumns" :extraParame="formData">
          </common-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, } from "vue";
  import { BasicApi, AnalyseApi, ScenicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { exportExcel, } from "@/utils/common.js";

  import dayjs from "dayjs";
  export default {
    components: {},
    setup() {
      const scenicOptions = ref([]);  // 园区选项
      const typeOptions = ref([]);  // 类型选项
      const activeName = ref('1');  // tabs id
      const formData = reactive({  // 筛选条件
        s_id: '',
        stt_id: '',
        month: dayjs().format("YYYY-MM"),
        search_type: '',  // 查询模式 1-汇总模式 2-明细模式
      });
      const summaryTableRef = ref(null);  // 汇总表格对象
      const summaryTableColumns = ref([  // 汇总表格配置
        {
          prop: "name",
          label: "类型",
        },
        {
          prop: "month",
          label: "所属月份",
        },
        {
          prop: "money_in",
          label: "收入",
        },
        {
          prop: "money_out",
          label: "消耗",
        },
        {
          prop: "money_remain",
          label: "剩余",
        },
      ])
      const detailTableRef = ref(null);  // 明细表格对象
      const detailTableColumns = ref([  // 明细表格配置
        {
          prop: "mcd_sn",
          label: "卡编号",
        },
        {
          prop: "ot_ticket_name",
          label: "门票名称",
        },
        {
          prop: "stt_name",
          label: "类型",
        },
        {
          prop: "month",
          label: "所属月份",
        },
        {
          prop: "money_in",
          label: "收入",
        },
        {
          prop: "money_out",
          label: "消耗",
        },
        {
          prop: "money_remain",
          label: "剩余",
        },
      ]);
      const summaryTables = ref([]);  // 导出表格数据
      /**
       * 
       * 获取园区列表数据
       * 
       * **/
      const getAllScenicData = () => {
        BasicApi.getAllScenicData({ auth: '1' }).then((res) => {
          if (res.Code === 200) {
            scenicOptions.value = res.Data ? res.Data : [];
            formData.s_id = scenicOptions.value.length > 0 ? scenicOptions.value[0].s_id : '';
            formData.search_type = '1';
            summaryTableRef.value.tableLoad();
          } else {
            let msg = res.Message ? res.Message : "获取园区数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 获取类型选项
       * 
       * **/
      const getTicketTypes = () => {
        ScenicApi.getNewTicketType().then((res) => {
          if (res.Code === 200) {
            typeOptions.value = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "获取门票类型数据失败！";
            ElMessage.error(msg);
          }
        });
      };
      /**
       * 
       * 切换tabs
       * 
       * **/
      const onSwitchTabs = () => {
        if (activeName.value == '1') {
          formData.search_type = '1';
          summaryTableRef.value.tableLoad();
        } else {
          formData.search_type = '2';
          detailTableRef.value.tableLoad();
        }
      }
      /**
       * 
       * 筛选类型事件
       * 
       * **/
      const selectChange = () => {
        if (activeName.value == '1') {
          formData.search_type = '1';
          summaryTableRef.value.tableLoad();
        } else {
          formData.search_type = '2';
          detailTableRef.value.tableLoad();
        }
      }
      // 成本分摊汇总导出配置
      const summaryConfig = ref([
        { field: "name", displayName: "类型" },
        { field: "month", displayName: "所属月份" },
        { field: "money_in", displayName: "收入" },
        { field: "money_out", displayName: "消耗" },
        { field: "money_remain", displayName: "剩余" },
      ]);
      // 成本分摊明细导出配置
      const infoConfig = ref([
        { field: "mcd_sn", displayName: "卡编号" },
        { field: "ot_ticket_name", displayName: "门票名称" },
        { field: "stt_name", displayName: "类型" },
        { field: "month", displayName: "所属月份" },
        { field: "money_in", displayName: "收入" },
        { field: "money_out", displayName: "消耗" },
        { field: "money_remain", displayName: "剩余" },
      ]);
      /**
       * 
       * 处理导出数据
       * 
       * **/
      const getLastData = () => {
        let title = "";  // 导出标题
        let data = null;  // 导出数据
        let config = null;  // 导出配置
        // 成本分摊汇总
        if (activeName.value === "1") {
          title = "成本分摊汇总";
          data = summaryTables.value;
          config = summaryConfig.value;
        } else {
          // 成本分摊明细
          title = "成本分摊明细";
          data = summaryTables.value;
          config = infoConfig.value;
        }
        return [title, data, config];
      }
      /**
       * 
       * 点击导出按钮
       * 
       * **/
      const handleExport = () => {
        let params = {
          limit: 10000000,
          page: 1,
          ...formData
        };
        AnalyseApi.getCost(params).then((res) => {
          if (res.Code === 200) {
            summaryTables.value = res.Data.list ? res.Data.list : [];
            // 导出
            try {
              exportExcel(
                getLastData()[0],
                getLastData()[1],
                getLastData()[2]
              );
              ElMessage.success({
                message: "导出成功！",
              });
            } catch (e) {
              ElMessage.error({
                message: "导出失败！",
              });
            }
          } else {
            ElMessage.error(res.Message);
          }
        });
      }

      onMounted(() => {
        getAllScenicData();
        getTicketTypes();
      });

      return {
        formData,
        scenicOptions,
        getAllScenicData,
        getTicketTypes,
        typeOptions,
        activeName,
        summaryTableRef,
        summaryTableColumns,
        AnalyseApi,
        ScenicApi,
        selectChange,
        onSwitchTabs,
        detailTableRef,
        detailTableColumns,
        handleExport,
        summaryConfig,
        summaryTables,
        getLastData,
        infoConfig,
      };
    },
  };
</script>

<style lang="scss">

</style>